<template>
  <h2>{{ $gettext('Professione Bartender') }}</h2>
  <div class="bartender-wrapper">
    <div class="bartender">
      <bartender-card
        v-for="item in listing"
        :key="item.id"
        :title="item.title"
        :header="item.imageUrl"
        :id="item.id"
      ></bartender-card>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import BartenderCard from '@/components/bartender/bartender-card';

export default {
  name: 'bartender-listing',
  components: { BartenderCard },

  data() {
    return {
      listing: [],
      imageUrl: '',
    };
  },

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },

    loadNews() {
      this.axios
        .get(this.api.bartenderListing + "?"  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          const listing = [];

          for (const id in res.data) {
            listing.push({
              id: res.data[id].id,
              title: this.domDecoder(res.data[id].title.rendered),
              imageUrl: '',
            });
            this.axios
              .get(this.api.getMediaUrl + res.data[id].featured_media)
              .then((res) => {
                this.listing[id].imageUrl = res.data.source_url;
              })
              .catch((err) => console.log(err));
          }

          this.listing = listing;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
  },

  mounted() {
    this.loadNews();
  },
};
</script>

<style scoped>
h1 {
  padding: 0 0 35px;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--var-secondary-color);
}

main {
  padding-left: 10px;
  padding-right: 10px;
}

.bartender {
  width: 90%;
  display: block;
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 0 auto 30px;
}
</style>
